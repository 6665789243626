var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-0", attrs: { cols: "auto" } },
    [
      _c("v-dialog", {
        attrs: { "max-width": "600", disabled: _vm.disableDialogLg },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_c("HistoryListItem", { attrs: { item: _vm.item } })],
                  1
                )
              ]
            }
          },
          {
            key: "default",
            fn: function(dialog) {
              return [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("div", {
                              staticClass: "mb-3",
                              class: _vm.computedTitleClass,
                              domProps: { innerHTML: _vm._s(_vm.item.title) }
                            }),
                            _c("div", { staticClass: "d-flex mt-2" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "secondary_font--text font-weight-regular action-text mr-1"
                                },
                                [_vm._v(_vm._s(_vm.item.action))]
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  staticClass: "primary--text font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tc(
                                        "globals.amount_points_short",
                                        _vm.item.points_amount
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm.item.subtitle
                              ? [
                                  _c("div", { staticClass: "d-flex mt-3" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "secondary_font--text font-weight-regular mb-1 mr-1"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("date")(
                                                _vm.item.created_at
                                              )
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("time")(
                                                _vm.item.created_at
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ml-3 secondary_font--text font-weight-regular"
                                      },
                                      [_vm._v(_vm._s(_vm.item.subtitle))]
                                    )
                                  ])
                                ]
                              : [
                                  _c("div", { staticClass: "d-flex mt-3" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mr-4 secondary_font--text font-weight-regular mb-1 mr-1"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("globals.date")) +
                                            ": " +
                                            _vm._s(
                                              _vm._f("date")(
                                                _vm.item.created_at
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "secondary_font--text font-weight-regular"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("globals.hour")) +
                                            ": " +
                                            _vm._s(
                                              _vm._f("time")(
                                                _vm.item.created_at
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ])
                                ]
                          ],
                          2
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-3",
                                attrs: { elevation: "0", color: "primary" },
                                on: {
                                  click: function($event) {
                                    dialog.value = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.close")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }