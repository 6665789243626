import SharedReversal from '@/shared/models/Reversal'
import CurrencyFilter from '@/utils/filters/currency'
import i18n from '@/i18n'
import $store from '@/store'

export default class Reversal extends SharedReversal {

    /**
     * Retorna a string identificadora de um ícone que representa a reversão
     *
     * @returns {string}
     */
    get icon() {
        return 'fas fa-times'
    }

    /**
     * Retorna um título completo que indica quantidade e valor
     *
     * @returns {string}
     */
    get title() {
        const expectedTypes = ['CNS', 'GNR']
        let   type          = expectedTypes.includes(this.type) ? this.type : 'default'
        let displayMode = $store.getters['company/display_mode']
        let hidePoints = $store.getters['company/hide_points']
        let hideCashback = $store.getters['company/hide_cashback']

        if (hidePoints) {
            return i18n.t(`models.Reversal.title.${type}.title_hide_points`, {
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (hideCashback) {
            return i18n.t(`models.Reversal.title.${type}.title_hide_cashback`, {
                points_amount: this.points_amount,
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (displayMode == 'PERCENTAGE' || displayMode == 'CASH')
            return i18n.t(`models.Reversal.title.${type}.title_percentage_cash`, {
                subsidiary_name: this.subsidiary_name,
                points_value: CurrencyFilter(this.points_value),
            })

        return i18n.t(`models.Reversal.title.${type}.title_points`, {
            points_value: CurrencyFilter(this.points_value),
            points_amount: this.points_amount,
            subsidiary_name: this.subsidiary_name,
        })
    }
    
    get titleMobile() {
        let hidePoints = $store.getters['company/hide_points']
        let hideCashback = $store.getters['company/hide_cashback']

        if (hidePoints) {
            return i18n.t(`models.Reversal.title.mobile_hide_points`, {
                subsidiary_name: this.subsidiary_name,
            })    
        }

        if (hideCashback) {
            return i18n.t(`models.Reversal.title.mobile_hide_cashback`, {
                points_amount: this.points_amount,
                subsidiary_name: this.subsidiary_name,
            })    
        }

        return i18n.t(`models.Reversal.title.mobile`, {
            points_value: this.points_value ? CurrencyFilter(this.points_value) : '',
            points_amount: this.points_amount,
            subsidiary_name: this.subsidiary_name,
        })
    }
    get action() {
        const expectedTypes = ['CNS', 'GNR']
        let   type          = expectedTypes.includes(this.type) ? this.type : 'default'
        return i18n.t(`models.Reversal.action.${type}`)
    }

    get subsidiary_name() {
        return this.company ? this.company.name : ''
    }

    /**
     * Retorna o valor total dos pontos estornados
     * 
     * @returns {Number}
     */
    get points_value() {
        return this.value
    }

    /**
     * Retorna a quantidade total de pontos estornados
     * 
     * @returns {Number}
     */
    get points_amount() {
        return this.amount
    }
}