var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { staticClass: "my-3" },
    [
      _c(
        "v-list-item-avatar",
        {
          class: _vm.$vuetify.breakpoint.xs ? "ml-n1" : "ml-2",
          attrs: { size: _vm.computedSizeIcon, tile: "" }
        },
        [
          _c("v-icon", { attrs: { color: "#dbdce6" } }, [
            _vm._v(_vm._s(_vm.item.icon))
          ])
        ],
        1
      ),
      _c(
        "v-list-item-content",
        { class: _vm.$vuetify.breakpoint.xs ? "px-0" : "px-4" },
        [
          _vm.$vuetify.breakpoint.mdAndDown
            ? _c("v-list-item-title", {
                class: _vm.computedTitleClass,
                domProps: { innerHTML: _vm._s(_vm.item.titleMobile) }
              })
            : _c("v-list-item-title", {
                class: _vm.computedTitleClass,
                domProps: { innerHTML: _vm._s(_vm.item.title) }
              }),
          _c(
            "v-list-item-subtitle",
            [
              _vm.item.subtitle
                ? [
                    _c(
                      "span",
                      {
                        staticClass:
                          "secondary_font--text font-weight-regular item-text"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("date")(_vm.item.created_at)) +
                            " " +
                            _vm._s(_vm._f("time")(_vm.item.created_at))
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "ml-3 secondary_font--text font-weight-regular item-text"
                      },
                      [_vm._v(_vm._s(_vm.item.subtitle))]
                    )
                  ]
                : [
                    _c(
                      "span",
                      {
                        staticClass:
                          "mr-4 secondary_font--text font-weight-regular item-text"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("globals.date")) +
                            ": " +
                            _vm._s(_vm._f("date")(_vm.item.created_at))
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "secondary_font--text font-weight-regular item-text"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("globals.hour")) +
                            ": " +
                            _vm._s(_vm._f("time")(_vm.item.created_at))
                        )
                      ]
                    )
                  ]
            ],
            2
          )
        ],
        1
      ),
      !_vm.hidePoints && _vm.$vuetify.breakpoint.smAndUp
        ? _c("div", { staticClass: "text-end" }, [
            _c(
              "span",
              {
                staticClass:
                  "secondary_font--text font-weight-regular action-text"
              },
              [_vm._v(_vm._s(_vm.item.action))]
            ),
            _c("br"),
            _c("span", { staticClass: "primary--text font-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm.$tc("globals.amount_points_short", _vm.item.points_amount)
                )
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }