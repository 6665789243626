<template>
    <v-col cols="auto" class="pa-0">
        <v-dialog max-width="600" :disabled="disableDialogLg">
            <!-- dialog view -->
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" >
                    <HistoryListItem :item="item"/>
                </div>
            </template>

            <!-- modal content-->
            <template v-slot:default="dialog">
                <v-card>
                    <v-col>
                        <v-card-text>
                            <div v-html="item.title" :class="computedTitleClass" class="mb-3"></div>

                            <div class="d-flex mt-2">
                                <span class="secondary_font--text font-weight-regular action-text mr-1">{{ item.action }}</span><br>
                                <span class="primary--text font-weight-bold">{{ $tc('globals.amount_points_short', item.points_amount) }}</span>
                            </div>

                            <template v-if="item.subtitle">
                                <div class="d-flex mt-3">
                                    <span class="secondary_font--text font-weight-regular mb-1 mr-1">
                                        {{ item.created_at | date }} {{ item.created_at | time }}
                                    </span>
                                    <span class="ml-3 secondary_font--text font-weight-regular">{{ item.subtitle }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="d-flex mt-3">
                                    <span class="mr-4 secondary_font--text font-weight-regular mb-1 mr-1">
                                        {{ $t('globals.date') }}: {{ item.created_at | date }}
                                    </span>
                                    <span class="secondary_font--text font-weight-regular">{{ $t('globals.hour') }}: {{ item.created_at | time }}</span>
                                </div>
                            </template>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn elevation="0" color="primary" class="px-3" @click="dialog.value = false"
                            >{{ $t('actions.close') }}</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </template>
        </v-dialog>
    </v-col>
</template>

<script>
import Generator   from '@/models/Generator'
import Consumption from '@/models/Consumption'
import Reversal    from '@/models/Reversal'

import HistoryListItem from '../listItems/HistoryListItem'

export default {
    name: 'HistoryDialog',
    components: { HistoryListItem },
    props: {
        item: {
            required: true,
            type: [Reversal, Generator, Consumption],
        },
    },
    computed: {
        computedTitleClass() {
            return {
                'text-decoration-line-through': ( this.item instanceof Generator || this.item instanceof Consumption ) && this.item.reversal_id,
                'primary_font--text': true,
                'title': true,
           }
        },
        disableDialogLg() {
            return this.$vuetify.breakpoint.lgAndUp ? true : false
        }
    }
}
</script>

<style>

</style>