<template>
    <v-list-item class="my-3">
        <v-list-item-avatar 
            :class="$vuetify.breakpoint.xs ? 'ml-n1':'ml-2'" 
            :size="computedSizeIcon"
            tile
        >
            <v-icon color="#dbdce6">{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content :class="$vuetify.breakpoint.xs ? 'px-0':'px-4'">
            <v-list-item-title v-if="$vuetify.breakpoint.mdAndDown" v-html="item.titleMobile" :class="computedTitleClass"></v-list-item-title>
            <v-list-item-title v-else v-html="item.title" :class="computedTitleClass"></v-list-item-title>
            <v-list-item-subtitle>
                <template v-if="item.subtitle">
                    <span class="secondary_font--text font-weight-regular item-text"> {{ item.created_at | date }} {{ item.created_at | time }}</span>
                    <span  class="ml-3 secondary_font--text font-weight-regular item-text">{{ item.subtitle }}</span>
                </template>
                <template v-else>
                    <span class="mr-4 secondary_font--text font-weight-regular item-text">{{ $t('globals.date') }}: {{ item.created_at | date }}</span>
                    <span class="secondary_font--text font-weight-regular item-text">{{ $t('globals.hour') }}: {{ item.created_at | time }}</span>
                </template>
            </v-list-item-subtitle>
        </v-list-item-content>

        <div v-if="!hidePoints && $vuetify.breakpoint.smAndUp" class="text-end">
            <span class="secondary_font--text font-weight-regular action-text">{{ item.action }}</span><br>
            <span class="primary--text font-weight-bold">{{ $tc('globals.amount_points_short', item.points_amount) }}</span>
        </div>
    </v-list-item>
</template>

<script>
import Consumption    from '@/models/Consumption'
import Generator      from '@/models/Generator'
import Reversal       from '@/models/Reversal'
import { mapGetters } from 'vuex'

export default {
    name: 'HistoryListItem',
    props: {
        item: {
            required: true,
            type: [Reversal, Generator, Consumption],
        },
    },
    methods: {
        showMore() {
            this.$router.push({ name: 'customers.register', params: { id: this.item.id, item: this.item } })
        }
    },
    computed: {
        ...mapGetters({
            hidePoints: 'company/hide_points',
        }),
        computedSizeIcon() {
            return this.$vuetify.breakpoint.xs ? 26 : 32
        },
        computedTitleClass() {
            return {
                'text-decoration-line-through': ( this.item instanceof Generator || this.item instanceof Consumption ) && this.item.reversal_id,
                'primary_font--text': true,
                'title': true,
           }
        },
    }
}
</script>

<style lang="scss">
.v-application .title {
    font-size: 16px !important;
    line-height: 20px;
}

.action-text {
    font-size: 14px;
}

.item-text {
    font-size: 12px;
}
</style>